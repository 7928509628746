import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import Slider from "../components/slide/Slider";
import { Consultas } from "../pages/Consultas";
import Contacto from "../pages/Contacto";
import { Home } from "../pages/Home";
import { Informacion } from "../pages/Informacion";
import { RegistroAguas } from "../pages/sub-pages/RegistroAguas";
import { RegistroComercio } from "../pages/sub-pages/RegistroComercio";
import { RegistroPropiedad } from "../pages/sub-pages/RegistroPropiedad";
import { ArchiveroJudicial } from "../pages/sub-pages/ArchiveroJudicial";
import { Tramites } from "../pages/Tramites";

export const AppRouter = () => {
  return (
    <Router>
      <div>
        {/*
         <Link to="/datos-electorales">
          <div
            style={{ background: "#2657d0", padding: "5px", color: "white" }}
          >
            <img
              style={{ marginRight: "5px" }}  
              src="./img/servel.png"
              width="20"
            />
            <b>ELECCIONES PRIMARIAS DE ALCALDE Y GOBERNADOR - TIERRA AMARILLA., acceda aquí.</b>
          </div>
      
        </Link> 
        */}

        <Header />
        <Switch>
          <Route
            exact
            path="/"
            component={() => (
              <Home
                title="Bienvenido"
                desc="El Conservador de Bienes Raíces, Comercio y Archivero Judicial de Copiapó, pone a su
disposición una nueva alternativa para realizar trámites en línea."
              />
            )}
          />
          <Route
            exact
            path="/tramites-en-linea"
            component={() => <Tramites title="Tramites en linea" desc="" />}
          />
          <Route
            exact
            path="/consultas-en-linea"
            component={() => <Consultas title="Consultas en Línea" desc="" />}
          />
          {/* <Route
            exact
            path="/informacion"
            component={() => <Informacion title="Informacion CBRC" />}
          /> */}
          <Route
            exact
            path="/registro-propiedad"
            component={RegistroPropiedad}
          />
          <Route exact path="/registro-comercio" component={RegistroComercio} />
          <Route exact path="/registro-aguas" component={RegistroAguas} />
          <Route exact path="/datos-electorales" component={Contacto} />
          <Route exact path="/archivero-judicial" component={ArchiveroJudicial} />
          {/* <Route exact path="/slider" component={Slider} /> */}
        </Switch>
        <Footer />
      </div>
    </Router>
  );
};
